import { useState } from 'react';

import Canvas from './Canvas';

function App() {
  const defaultSettings = {
    height: 800,
    width: 800,
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    croshair_isActive: true,
  };

  const [settings, setSettings] = useState(defaultSettings);

  const handleCroshairState = () => {
    setSettings({
      ...defaultSettings,
      croshair_isActive: !settings.croshair_isActive
    });
  };

  return (
    <>
      <Canvas settings={settings} />
    </>
  );
}

export default App;
