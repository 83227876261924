const drawRect = (
  context,
  {
    startX,
    startY,
    width,
    height,
    style: { strokeColor, strokeWidth, backgroundColor },
  }
) => {
  context.beginPath();
  context.strokeStyle = strokeColor;
  context.lineWidth = strokeWidth;
  context.fillStyle = backgroundColor;
  context.rect(startX + 0.5, startY + 0.5, width, height);
  context.closePath();
  context.fill();
  context.stroke();
};

export default drawRect;
