import { useState, useEffect } from 'react';
// draw functions
import drawLine from './draw/drawLine';
import drawRect from './draw/drawRect';
import drawText from './draw/drawText';

const useCroshair = () => {
  const [context, setContext] = useState(null);
  const initCtx = (ctx) => setContext(ctx);

  const [lineX, updateX] = useState({
    startX: 0,
    startY: 500,
    endX: window.innerWidth - 105,
    endY: 500,
    style: {
      color: 'rgba(0, 0, 0, 100%)',
      width: 1,
    },
  });

  const [lineY, updateY] = useState({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: window.innerHeight,
    style: {
      color: 'rgba(0, 0, 0, 100%)',
      width: 1,
    },
  });

  const [rect, updateRect] = useState({
    startX: window.innerWidth - 105,
    startY: 500,
    width: 100,
    height: 20,
    style: {
      backgroundColor: 'rgba(200, 255, 255, 20%)',
      strokeColor: 'rgba(255, 0, 0, 100%)',
      strokeWidth: 1,
    },
  });

  const [text, updateText] = useState({
    startX: window.innerWidth - 100,
    startY: 0,
    text: '1.29503',
    style: {
      font: '12px ubuntu',
      color: 'blue',
    },
  });

  useEffect(() => {
    if (context) {
      drawLine(context, lineX);
      drawLine(context, lineY);
      drawRect(context, rect);
      drawText(context, text);

    }
  }, [lineX, lineY]);

  const handleMouseMove = ({ clientX, clientY }) => {
    updateX({
      ...lineX,
      startY: clientY,
      endY: clientY,
    });
    updateY({
      ...lineY,
      startX: clientX,
      endX: clientX,
    });
    updateRect({
      ...rect,
      startY: clientY - 10,
    });
    updateText({
      ...text,
      startY: clientY + 6,
      text: `Y: ${clientY} X: ${clientX}`,
    });
  };

  return { handleMouseMove, initCtx };
};
export default useCroshair;
