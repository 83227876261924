import { useRef, useEffect, useState } from 'react';
import drawRect from './draw/drawRect';
import drawText from './draw/drawText';
import useCroshair from './useCroshair';
import drawObjects from './drawObjects';
import drawLine from './draw/drawLine';

const Canvas = () => {
  const canvasRef = useRef(null);

  // const [zoom, setZoom] = useState(1);
  // const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [isDrawing, setDrawing] = useState(false);
  const [newobject, setNewObject] = useState(null);

  const [objects, setObjects] = useState([
    {
      type: 'rectangle',
      startX: 0,
      startY: 0,
      height: 50,
      width: 50,
      style: {
        strokeColor: 'red',
        strokeWidth: 1,
        backgroundColor: 'yellow',
      },
    },
    {
      type: 'rectangle',
      startX: 100,
      startY: 100,
      height: 50,
      width: 50,
      style: {
        strokeColor: 'blue',
        strokeWidth: 1,
        backgroundColor: 'white',
      },
    },
    {
      type: 'line',
      startX: 500,
      startY: 0,
      endX: 500,
      endY: 500,
      style: {
        color: 'black',
        lineWidth: 5,
      },
    },
    {
      type: 'text',
      text: 'Hello Endri Hoxha',
      startX: 420,
      startY: 520,
      style: {
        font: '20px ubuntu',
        color: 'red',
      },
    },
    {
      type: 'text',
      text: 'NFUKSLFHKFSH',
      startX: 420,
      startY: 520,
      style: {
        font: '30px ubuntu',
        color: 'blue',
      },
    },
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;
    const context = canvas.getContext('2d');

    // draw all objects in the state
    drawObjects(context, objects);
    // live draw only whne isDrawing = true
    isDrawing && drawLine(context, newobject);

    console.log(objects);
  }, [newobject, objects]);

  const handleMouseMove = ({ clientX, clientY }) => {
    if (isDrawing) {
      setNewObject({
        ...newobject,
        endX: clientX,
        endY: clientY,
      });
    }
  };

  const startDrawing = ({ clientX, clientY }) => {
    setNewObject({
      type: 'line',
      startX: clientX,
      startY: clientY,
      endX: clientX,
      endY: clientY,
      style: {
        color: 'black',
        lineWidth: 1,
      },
    });
    setDrawing(true);
  };

  const stopDrawing = ({ clientX, clientY }) => {
    setDrawing(false);
    setObjects([...objects, newobject]);
    setNewObject({});
  };

  return (
    <canvas
      ref={canvasRef}
      onMouseMove={handleMouseMove}
      onMouseDown={startDrawing}
      onMouseUp={stopDrawing}
    />
  );
};

export default Canvas;
