const drawText = (
  context,
  { startX, startY, text, style: { font, color } }
) => {
  context.font = font;
  context.fillStyle = color;
  context.fillText(text, startX, startY);
};

export default drawText;
