import drawLine from './draw/drawLine';
import drawRect from './draw/drawRect';
import drawText from './draw/drawText';

const drawObjects = (context, objects) => {
  objects.map((object) => {
    switch (object.type) {
      case 'rectangle':
        drawRect(context, object);
        break;
      case 'line':
        drawLine(context, object);
        break;
      case 'text':
        drawText(context, object);
        break;
      default:
        console.log('default called', object);
        break;
    }
  });
};

export default drawObjects;
